import React from 'react';
import { Link } from 'gatsby';

import { StyledNav } from './styles';

export default function Nav() {
  return (
    <StyledNav aria-label="Main Navigation">
      <Link to="/" aria-label="Homepage" className="logo">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65 65">
          <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width=".5" d="m64 3-7 13M2 3l7 13m32 30-8 15m-8-15 8 15m5-54v44M36 5v50M63 5H36m5 4v37M61 7H38m-4 52V3m0 0h30M41 9h19m-3 7H41m17-2H41m18-2H41M28 7v43m2-45v50M4 5h26m-5 4v37M5 7h23m4 52V3m0 0H2m23 6H6m3 7h16M8 14h17M7 12h18" />
        </svg>
      </Link>
      <Link to="/">home</Link>
      <Link to="/about">about</Link>
    </StyledNav>
  );
}
